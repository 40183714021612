/* ------------------------------------------------------------------ */
/* b. Header Styles
/* ------------------------------------------------------------------ */

.main section {
  margin: 0;
  min-height: 100%;
  height: 100vh;
  overflow: auto;
}

.banner {
  position: relative;
  height: 100%;
  width: 100%;
  
  text-align: center;
  overflow: auto;
}

body {
  margin: 0;
  min-height: 100%;
}

/* vertically center banner section */
.banner:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.sub-banner {
  display: inline-block;
  margin: 0 auto;
  width: 85%;
  padding-bottom: 30px;
  text-align: center;
}

.lower-banner {
  display: inline-block;
  vertical-align: bottom;
  margin: 0 auto;
  width: 90%;
  padding-bottom: 30px;
  text-align: center;
}

.row-container {
  vertical-align: middle;
  margin: 0 auto;
  width: 85%;
  padding-bottom: 30px;
  text-align: center;
}

.skill-container {
  vertical-align: middle;
  margin: 0 auto;
  width: 85%;
  padding-bottom: 30px;
  text-align: center;
}

.info {
  font: 16px/24px 'librebaskerville-italic', serif;
  color: #6E7881;
  margin-bottom: 18px;
  margin-top: 9px;
}
.info span {
  margin-right: 5px;
  margin-left: 5px;
}

.line-spacer {
  height: 670px;
  width: 2px;
  background-color: black;
  margin: 0 20px;
  display: inline-block;
}

.left-col { padding-right: 50%; }

.right-col { padding-left: 50%; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a { cursor: pointer; }

.app-container {
  height: fit-content;
  background-color: "#164773";
}

.container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: "#164773";
}

.container-header {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: "#164773";
}

.container-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.primary-btn {
  margin: 10px;
  padding: 10px;
  background-color: black;
  color: white;
  border: 0px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}

.header-wrapper {
  width: 80vw;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.highlight-text {
  text-shadow: 0 0 1px white; 
  color: black;
  text-align: center;
  flex-grow: 1;
}

.highlight-text-white {
  text-shadow: 0 0 1px black 0 0 1px black 0 0 1px black; 
  color: white;
  text-align: center;
  flex-grow: 1;
}
.section-one {
  background-color: #3e5050;
}
.section-two {
  overflow: hidden;
  background-color: #495f5f;
}
.section-three {
  background-color: #546d6d;
}
.section-four {
  background-color: #5f7c7c;
}
.section-five {
  background-color: #6b8a8a;
}
.main section h1 {
  font-size: 38px;
  color: #fff;
  margin: 0;
  padding: 50px 0 20px;
  text-align: center;
}
